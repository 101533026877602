.button-container {
  align-items: center;
  /* background: #fffaf4; */
  bottom: 0;
  display: flex;
  height: 119px;
  left: 0;
  margin: 0;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.btn-welcome {
  background: #000;
  border: 0.8px solid #ccb7b7;
  border-radius: 6px;
  color: #fff;
  font-family: SteradianRegular;
  font-size: 15px;
  height: 45px;
  letter-spacing: .32px;
  margin: 50px auto;
  text-align: center;
  width: 234px;
}

button.btn-fields, button.btn-fields-terminer {
  background: transparent;
  border: 0.8px solid #ccb7b7;
  border-radius: 6px;
  color: #ccb7b7;
  font-family: SteradianRegular;
  font-size: 15px;
  height: 45px;
  left: 0;
  letter-spacing: .32px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  text-align: center;
  width: 162px;
}

.btn-active {
  background-color: #000;
  color: #fff !important;
}

:disabled {
  cursor: default;
}