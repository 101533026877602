.switch-container {
  position: relative;
  width: 44px;
  height: 29px;


  .switch-input {
    display: none;
  }

  .switch-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 44px;
    height: 29px;
    border-radius: 15px;
    background-color: #d2d2d2;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }

  .switch-circle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #FFFAF5;

    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }

  .switch-input:checked+.switch-label {
    background-color: #000;
  }

  .switch-input:checked+.switch-label .switch-circle {
    transform: translateX(15px);
  }
}

.back {
  #swiperPrevImae {
    margin-bottom: -15px;
  }

  .swiper-pagination-bullet {
    border-radius: 50%;
    display: inline-block;
    margin: 0 2px !important;
    height: 7px !important;
    width: 7px !important;

  }

  .swiper-pagination-bullet-active {
    background-color: #000;
    border-radius: 50px;
    width: 18px !important;

  }
}

.custom-swiper-pagination {

  bottom: 5px;
  /* Adjust this value according to your preference */
}