$primaryColor : #CCB7B7;

.popup-background {
    background-color: rgba(0, 0, 0, 0.516);
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;

    &--hide {
        display: none;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

.slide-up {
    animation: slideUp 0.5s ease-in-out;
}

@keyframes slideDown {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(100%);
    }
}

.slide-down {
    animation: slideDown 0.5s ease-in-out;
}


.NotificationPopup {

    
    &--hide {
        display: none;
    }

    position: fixed;
    background-image: url(../assets/bgNotification.svg
);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;

    bottom: 0;
    width: 100%;
    height: 550px;
    z-index: 1000;
    background-color: #fff;
    border-radius: 30px 30px 0px 0px;
    box-shadow: 0px 2.9164px 14.582px -2.9164px rgba(89, 47, 47, 0.189087);

    .close-icon {
        position: absolute;
        width: 15px;
        height: 15px;
        object-fit: cover;
        right: 20px;
        top: 20px;
        color: #000;
        cursor: pointer;
    }

    &__content {
        margin-top: 10px;
        padding: 30px;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;

        &__title {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            .avatar-p {
                width: 60px;
                height: 60px;
                object-fit: cover;
                border-radius: 50%;
                overflow: hidden;
                min-width: 60px;
                min-height: 60px;
                .avatar {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .pargh {
                font-size: 18px;
                font-weight: 600;
                color: #000;
                margin-left: 20px;
            }

        }

        &__body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .inputWrapper {
                display: flex;
                flex-direction: column;
                margin-top: 20px;

                .input {
                    width: 100%;
                    background-color: #fff;
                    border: 1px solid $primaryColor;
                    padding: 10px 20px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #000;
                    margin-bottom: 0px;
                    border-radius: 15.434px !important;

                }

                .inputError {
                    border: 1px solid rgb(255, 67, 67);
                }

                .input::placeholder {
                    color: $primaryColor;
                    font-size: 16px;
                }
            }

            .buttonWrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;

                margin: 20px auto;
            }

            .textWrapper {
                display: flex;
                align-items: center;

                .text {
                    font-size: 14px;
                    font-weight: 600;
                    color: #000;
                    width: fit-content;
                }

                .icon {
                    font-size: 20px;
                }
            }

        }
    }

}

.CameraPopup {
    position: fixed;
    bottom: 50%;
    width: 200px;
    left: 23%;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 2.9164px 14.582px -2.9164px rgba(89, 47, 47, 0.189087);
    z-index: 1000;

}

.takePopup {
    position: fixed;
    bottom: 50%;
    width: 200px;
    left: 23%;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 2.9164px 14.582px -2.9164px rgba(89, 47, 47, 0.189087);
    z-index: 1000;

}

// take picture section
.TakePictureModal {
    &__content {
        &__camera {
            &__video {
                width: 100%;
                height: 100%;
                object-fit: cover;

                .video {

                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    background: #000;
                }
            }
        }
    }
}

// end take picture section
video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background: #000;
}

.camera-icon {
    position: fixed;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);

    cursor: pointer;
    width: 40px;
    height: 40px;
    color: #fff;
}