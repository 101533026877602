@keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes macProgress {
    0% {
      transform: translateX(-100%);
    }
    20% {
      transform: translateX(-60%);
    }
    50% {
      transform: translateX(-25%);
    }
    75% {
      transform: translateX(0%);
    }
    90% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  .animate-slideIn {
    animation: slideIn 0.3s ease-out forwards;
  }

  .animate-macProgress {
    animation: macProgress 2s ease-in-out infinite;
  }