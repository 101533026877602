.dropdown-container {
    position: relative;
    width: 90%;
    margin: 0 auto;
    z-index: 2;
}

.custom-dropdown {

    display: inline-block;
    width: 100%;

    &__selected-item {

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem;
        cursor: pointer;
        border: 1px solid #4E3131;
        font-size: 20px;
        border-radius: 18px;

        &:focus {
            outline: none;
        }
    }

    &__flag {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        margin-right: 0.5rem;
        background-size: cover;
    }

    &__language {
        flex-grow: 1;
    }

    &__arrow {
        margin-left: 0.5rem;
    }

    &__dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        margin-top: -50px;
        z-index: -1;
        border: 1px solid #4E3131;
        border-radius: 18px;

        &:focus-within {
            outline: none;
        }
    }

    &__option {

        display: flex;
        align-items: center;
        padding: 8px;
        cursor: pointer;
        font-size: 20px;
        border-radius: 0px 0px 18px 18px;
        margin-top: 40px;
        padding-top: 18px;

        &:hover {
            background-color: #FFDBB3;
        }

        &__flag {
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            margin-right: 0.5rem;
            background-size: cover;
        }

        &__language {
            flex-grow: 1;
        }
    }
}