$primaryColor : #CCB7B7;


.form-pass {
    margin-top: 3rem;

    label {
        margin-bottom: 4px;
    }

    .password-input-container {
        width: 95%;
        position: relative;
    }

    .password-toggle {
        position: absolute;
        top: 68%;
        right: 0.7rem;
        transform: translateY(-50%);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .password-toggle svg {
        width: 1.5rem;
        height: 1.5rem;
    }

    .input__field {

        width: 100%;
        border: 1px solid $primaryColor;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 400;
        color: #000;
        margin-bottom: 0px;
        border-radius: 9px !important;
    }

    .input-error {
        width: 100%;
        border: 1px solid rgb(255, 67, 67);
        background-color: #f252523c;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 400;
        color: #000;
        margin-bottom: 0px;
        border-radius: 9px !important;
    }

    .input__field::placeholder {
        color: $primaryColor;
        font-size: 16px;
    }

}